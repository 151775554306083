import React from 'react'
import { Dialog, Grid, SvgIcon } from '@material-ui/core'
import { ReactComponent as LogoIcon } from './../icons/logos/logo512.svg'

const SuspenseFallback: React.FC = () => {
    return (
        <Dialog fullScreen open={true}>
            <Grid container direction="row" justify="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
                <div>
                    <SvgIcon component={LogoIcon} viewBox="0 0 512 512" fontSize="large" style={{ height: 100, width: 100 }} />
                </div>
            </Grid>
        </Dialog>
    )
}

export default SuspenseFallback