import React, { useState, useEffect, Suspense, lazy } from 'react';
import { RecoilRoot } from 'recoil'
import './Web.css';

//Modules
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'


//Material UI
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import amber from '@material-ui/core/colors/amber'

//App
import SuspenseFallback from './web/web-suspense'
const  WebToolBar = lazy(() => import('./web/web-tool-bar'))
const  LeftMenu = lazy(() => import('./web/web-left-menu'))
const  WebRoutes = lazy(() => import('./web/web-routes'))


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F7B64C"
    },
    secondary: amber,
    text: {
      primary: "#415a6b"//"#767171"
    }
  }
})

theme = responsiveFontSizes(theme)

const Web: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Yu Moments</title>
        <meta name="description" content="Comparte vuestros momentos y hazlos inolvidables" />
      </Helmet>
      <Router>
        <ScrollToTop />
        <CssBaseline />
        <RecoilRoot>
          <Suspense fallback={<SuspenseFallback />}>
            <WebToolBar open={open} setOpen={setOpen} />
            <LeftMenu open={open} setOpen={setOpen} />
            <WebRoutes />
          </Suspense>
        </RecoilRoot>
      </Router>
    </ThemeProvider>
  );
}

export default Web

/*




*/


/*
<div className={classes.root}>

</div>
*/

/*
<Helmet htmlAttributes={{ lang: appSettings.language.userLanguage }} />

*/

/*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    */